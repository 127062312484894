<template>
    <div id="sc-customers">
        <div id="sc-header">
            <h3>Customers</h3>
            <b-button class="sc-button-primary" @click="onClickGoToNewCustomer">Add new</b-button>
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="customersList.length == 0" class="sc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Table -->
        <v-client-table v-else id="sc-customers-table" :data="customersList" :columns="columns" :options="options">
            <div class="sc-actions-container" slot="actions" slot-scope="{ row }">
                <b-icon-arrow-right-circle-fill class="sc-icon-view" @click="onClickViewCustomer(row.id)"></b-icon-arrow-right-circle-fill>
                <b-icon-pencil-square class="sc-icon-edit" @click="onClickEditCustomer(row.id)"></b-icon-pencil-square>
            </div>
        </v-client-table>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "Customers",
    data() {
        return {
            columns: ["id", "name", "phone", "truck", "balance", "carryOver", "quickbooksId", "actions"],
            customersList: [],
            options: {
                perPage: 200,
                orderBy: {
                    column: "name",
                    ascending: true,
                },
                headings: {
                    productsId: "Products",
                    quickbooksId: "QB Id",
                    carryOver: "Prepay",
                },
                templates: {},
                cellClasses: {
                    id: [
                        {
                            class: "customer-standing-good",
                            condition: (row) => !row.standing || row.standing === "1",
                        },
                        {
                            class: "customer-standing-average",
                            condition: (row) => row.standing === "2",
                        },
                        {
                            class: "customer-standing-bad",
                            condition: (row) => row.standing === "3",
                        },
                    ],
                },
            },
        };
    },
    methods: {
        onClickDeleteCustomer(id) {
            this.deleteCustomer(id);
        },
        onClickEditCustomer(id) {
            this.$router.push({ path: "/edit-customer/" + id });
        },
        onClickGoToNewCustomer() {
            this.$router.push({ path: "/new-customer" });
        },
        onClickViewCustomer(id) {
            this.$router.push({ path: "/view-customer/" + id });
        },
        async deleteCustomer(id) {
            let response = await APICalls.deleteCustomer(id);
            console.log(response);
            this.getCustomers();
        },
        async getCustomers() {
            try {
                let customers = await APICalls.getCustomers();
                this.customersList = customers;
            } catch (errResponse) {
                console.log(errResponse);
                this.$router.push({ path: "/login" });
            }
        },
    },
    mounted() {
        this.getCustomers();
    },
};
</script>

<style lang="scss">
#sc-customers {
    #sc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .sc-actions-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .sc-icon-view {
            width: 22px;
            height: 22px;
            color: black;
            cursor: pointer;
        }

        .sc-icon-edit {
            width: 22px;
            height: 22px;
            margin-left: 30px;
            color: black;
            cursor: pointer;
        }

        .sc-icon-delete {
            width: 22px;
            height: 22px;
            margin-left: 30px;
            color: #800000;
            cursor: pointer;
        }

        .sc-button-primary {
            margin-top: 10px;
            width: 160px;
        }
    }

    #sc-customers-table {
        table {
            tbody {
                tr {
                    td:nth-child(1) {
                        font-weight: bold;
                        text-align: center;
                    }
                    td:nth-child(3) {
                        max-width: 150px;
                    }
                    td:nth-child(4) {
                        max-width: 125px;
                    }
                    td:nth-child(5) {
                        max-width: 40px;
                    }
                    td:nth-child(6) {
                        max-width: 40px;
                    }
                    // td:nth-child(7) {
                    //     max-width: 50px;
                    // }
                }

                .customer-standing-good {
                    background-color: #d9ead3;
                }
                .customer-standing-average {
                    background-color: #fff2cc;
                }
                .customer-standing-bad {
                    background-color: #f4cccc;
                }
            }
        }
    }
}
</style>
